@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="checkbox"] {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}

.dynamic-shadow-dom {
  --dynamic-base-1: #042439;
  --dynamic-base-2: #1d394c;
  --dynamic-base-3: #042439;
  --dynamic-base-4: #1d394c;
  --dynamic-base-5: #1d394c;
  --dynamic-border-radius: 10px;
}
