@import "bootstrap/scss/functions";
@import "./variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/mixins";
@import "~bootstrap/scss/maps";
@import "bootstrap/scss/root";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/utilities/api";
@import "./mixin";
@import "./fonts";
@import "./utilities";

@font-face {
	font-family: "BentonSans Bold";
	src: url("/fonts/BentonSans Bold.otf");
	font-style: normal;
	font-weight: bold;
	font-display: swap;
}

@font-face {
	font-family: "BentonSans";
	src: url("/fonts/BentonSans Regular.otf");
	font-style: normal;
	font-weight: normal;
	font-display: swap;
}

@font-face {
	font-family: "MercuryTextG1";
	src: url("/fonts/MercuryTextG1 Regular.otf");
	font-style: normal;
	font-weight: normal;
	font-display: swap;
}

@font-face {
	font-family: "MercuryDisplay";
	src: url("/fonts/MercuryDisplayRoman.ttf");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "BentonSans RegularItalic";
	src: url("/fonts/BentonSans RegularItalic.otf");
	font-style: italic;
	font-weight: normal;
	font-display: swap;
}

@font-face {
	font-family: "BentonSans Medium";
	src: url("/fonts/BentonSans Medium.otf");
	font-style: normal;
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: "BentonSans Thin";
	src: url("/fonts/BentonSans Thin.otf");
	font-style: normal;
	font-weight: 500;
	font-display: swap;
}

@font-face {
	font-family: "BentonSans Light";
	src: url("/fonts/BentonSans Light.otf");
	font-style: normal;
	font-weight: 500;
	font-display: swap;
}

html,
body {
	min-height: 100vh;
	font-family: "BentonSans", sans-serif;
	font-weight: 200;
	overflow: hidden;
	color: rgb(10, 10, 10);
}

@media screen and (max-width: 37.6em) {
	body {
		overflow-y: scroll;
	}
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

ul[class],
ol[class] {
	padding: 0;
}

body,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

body {
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
}

::-webkit-scrollbar {
	width: 5px;
}

// /* Track */
// ::-webkit-scrollbar-track {
//   background: rgba(254,21,188,0.2);
//   border-radius: 3px;
// }

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--scrollbar);
	border-radius: 3px;
}

main {
	width: 100%;
	height: 100%;
}

ul[class],
ol[class] {
	list-style: none;
}

a:not([class]) {
	text-decoration-skip-ink: auto;
}

img {
	max-width: 100%;
	display: block;
}

article>*+* {
	margin-top: 1em;
}

input,
button,
textarea,
select {
	font: inherit;
}

button {
	cursor: pointer;
}

a {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
}

@media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

// wallet-checkout css
.checkout-makeoffer {
	.total-amount-offer {
		font-family: 'IBM Plex Sans';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 24px;
		color: #000000;

	}
}