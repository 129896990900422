// Fonts
$font-family: "BentonSans", sans-serif;
$font-family-headers: "BentonSans", sans-serif;

$font-weights: (
  default: 0,
  300: 300,
  400: 400,
  500: 500,
  600: 600,
);

$font-families: (
  ibm-mono: $font-family-headers,
  ibm-sans: $font-family,
);

@each $keyW, $weight in $font-weights {
  @each $keyF, $family in $font-families {
    @if $keyW==default {
      .#{$keyF} {
        font-family: #{$family} !important;
      }
    } @else {
      .#{$keyF}-#{$weight} {
        font-family: #{$family} !important;
        font-weight: #{$weight} !important;
      }
    }
  }
}

h1,
.typo-h1 {
  font-weight: 700;
  font-family: $font-family-headers;
  font-size: 60px;
  line-height: 60px;
}

h2,
.typo-h2 {
  font-weight: 600;
  font-family: $font-family-headers;
  font-size: 48px;
  line-height: 48px;
}

h3,
.typo-h3 {
  font-weight: 600;
  font-family: $font-family-headers;
  font-size: 34px;
  line-height: 34px;
}

h4,
.typo-h4 {
  font-weight: 600;
  font-family: $font-family-headers;
  font-size: 24px;
  line-height: 28.8px;
}

h5,
.typo-h5 {
  font-weight: 600;
  font-family: $font-family-headers;
  font-size: 20px;
  line-height: 24px;
}

.typo-subtitle {
  font-weight: 600;
  font-family: $font-family-headers;
  font-size: 16px;
  line-height: 16px;
}

.typo-subtitle2 {
  font-weight: 600;
  font-family: $font-family;
  font-size: 14px;
  line-height: 14px;
}

.typo-body {
  font-weight: 400;
  font-family: $font-family;
  font-size: 16px;
  line-height: 22px;
}

.typo-body2 {
  font-weight: 400;
  font-family: $font-family;
  font-size: 14px;
  line-height: 20px;
}

.typo-body3 {
  font-weight: 400;
  font-family: $font-family;
  font-size: 12px;
  line-height: 16.8px;
}

.typo-caption {
  font-weight: 400;
  font-family: $font-family;
  font-size: 12px;
  line-height: 13.2px;
}

.typo-caption2 {
  font-weight: 400;
  font-family: $font-family;
  font-size: 10px;
  line-height: 11px;
}
.typo-link1 {
  font-weight: 600;
  font-family: $font-family-headers;
  font-size: 14px;
  line-height: 15.4px;
  text-transform: uppercase;
}
.typo-button {
  font-weight: 600;
  font-family: $font-family-headers;
  font-size: 16px;
}

// Overload bootstrap fonts variables
$font-family-base: $font-family;
$headings-font-family: $font-family;
