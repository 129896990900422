@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-n#{$size} {
          #{$prop}: -#{$length} !important;
        }
        .#{$abbrev}t#{$infix}-n#{$size},
        .#{$abbrev}y#{$infix}-n#{$size} {
          #{$prop}-top: -#{$length} !important;
        }
        .#{$abbrev}r#{$infix}-n#{$size},
        .#{$abbrev}x#{$infix}-n#{$size} {
          #{$prop}-right: -#{$length} !important;
        }
        .#{$abbrev}b#{$infix}-n#{$size},
        .#{$abbrev}y#{$infix}-n#{$size} {
          #{$prop}-bottom: -#{$length} !important;
        }
        .#{$abbrev}l#{$infix}-n#{$size},
        .#{$abbrev}x#{$infix}-n#{$size} {
          #{$prop}-left: -#{$length} !important;
        }
      }
    }
  }
}
