// Viewport
$viewport-xs-min-w: 375px;
$viewport-sm-min-w: 576px;
$viewport-md-min-w: 768px;
$viewport-lg-min-w: 992px;
$viewport-xl-min-w: 1200px;
$viewport-xs-w: 374px;
$viewport-sm-w: 575px;
$viewport-md-w: 767px;
$viewport-lg-w: 991px;
$viewport-xl-w: 1199px;

// Overall
$border-box-radius: 3px;
$border-radius: 3px;
$border-shadow-color: rgb(38, 51, 75);

// Form
$form-elements-height: 48px;

// Tabs
$tabs-navigation-height: 38px;

$input-height: $form-elements-height;
$button-height: $form-elements-height;
$datepicker-height: $form-elements-height;
$radio-height: $form-elements-height;
$select-height: 46px;

// Button
$btn-size-sm: 35px;
$btn-size-md: 50px;
$btn-size-lg: 65px;

// Main Menu
$menu-height-main-lg: 110px;
$menu-height-main-md: 110px;
$menu-height-main-sm: 60px;
$menu-height-main-fixed-lg: 68px;
$menu-height-main-fixed-md: 68px;
$menu-height-main-fixed-sm: 60px;

// Notification
$notification-top-main-lg: 140px;
$notification-top-main-md: 98px;
$notification-top-main-sm: 90px;
$notification-top-main-fixed-lg: 66px;
$notification-top-main-fixed-md: 68px;
$notification-top-main-fixed-sm: 60px;

// Portal Menu
$menu-height-portal-lg: 110px;
$menu-height-portal-md: 70px;
$menu-height-portal-sm: 70px;
$menu-height-portal-fixed-lg: 110px;
$menu-height-portal-fixed-md: 70px;
$menu-height-portal-fixed-sm: 70px;

// Portal Desktop Dropdown Menu
$menu-dropdown-height-portal: 90px;

// Top Panel
$top-panel-height-main: 30px;

// Colors
$dark-blue: #337fff;
$blue: #1E88E5;
$dark-text: #212121;
$text: #737373; // original #6f6f6f;
$grey-bg-light: #f8f8f9;
$grey-bg: #f5f6f8;
$dark-grey: #b0b4b9; // original: #cacfd5;
$grey: #f1f1f1;
$red: #ea3131;
$green: #1ac485;
$yellow: #ffba3a;
$orange: #ed8716;

// Grid
$grid-columns: 12;
$grid-gutter-width: 28px !default;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  sm: 544px,
  md: 750px,
  lg: 964px,
  xl: 1172px,
);

$spacer: 1rem; // ~16px;
$spacers: (
  0: (
    $spacer * 0,
  ),
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: (
    $spacer * 0.625,
  ),
  4: (
    $spacer * 0.75,
  ),
  5: (
    $spacer * 0.876,
  ),
  6: (
    $spacer * 1,
  ),
  7: (
    $spacer * 1.25,
  ),
  8: (
    $spacer * 1.5,
  ),
  9: (
    $spacer * 1.75,
  ),
  10: (
    $spacer * 2,
  ),
  11: (
    $spacer * 2.5,
  ),
  12: (
    $spacer * 3,
  ),
  13: (
    $spacer * 3.5,
  ),
  14: (
    $spacer * 4,
  ),
  15: (
    $spacer * 4.5,
  ),
  16: (
    $spacer * 5,
  ),
  17: (
    $spacer * 6,
  ),
  18: (
    $spacer * 7,
  ),
  19: (
    $spacer * 8,
  ),
  20: (
    $spacer * 9,
  ),
);
